<template>
  <v-container>
    <div class="d-flex justify-space-between">
      <div class="d-flex align-center">
        PERMISSIONS
      </div>
      <div class="d-flex p-3 items-center">
        <span style="line-height: 1.7rem"><toggle-component :model-value="isSwitched"
            @updateModel="updateToggle($event)"></toggle-component></span>
        <p class="ml-3">Enable All</p>
      </div>
    </div>

    <v-divider></v-divider>
    <div>
      <v-card v-for="(card, index) in getCardData" :key="card.name"
        class="d-flex justify-space-between items-center mb-5">
        <div  style="color: #696F8C;">
          <v-card-title>{{ card.name }}</v-card-title>
          <v-card-text>{{ card.content }}</v-card-text>
        </div>
        <span :key="card.name">
          <flex-col-toggle :model-value="card.permit" @updateValue="updateValue($event, index)" :key="index">
          </flex-col-toggle>
        </span>
      </v-card>
    </div>
    <access-btns :model-value="nextBtn" @updateModel="updateModel($event)" @reset="reset()"></access-btns>
  </v-container>
</template>

<script>
import flexColToggle from "./flexColToggle.vue";
import toggleComponent from "./toggleComponent.vue";
import AccessBtns from "./AccessBtns.vue";

export default {
  components: {
    toggleComponent,
    flexColToggle,
    AccessBtns,
  },
  data() {
    return {
      isSwitched: false,
      nextBtn: false,
      cardData: [
        {
          name: "Super Admin",
          content: "Permit access to all features",
          permit: false,
          permission: "SUPER_ADMIN"
        },
        {
          name: "Manager",
          content: "Permit access to features related to management",
          permit: false,
          permission: "MANAGER"
        },
        {
          name: "Accountant",
          content: "Permit access to features related to accounting",
          permit: false,
          permission: "ACCOUNTANT"
        },
        {
          name: "Senior Accountant",
          content: "Permit access to features related to senior accounting",
          permit: false,
          permission: "SENIOR_ACCOUNTANT"
        }
      ],
    };
  },

  computed: {
    getCardData() {
      return this.cardData;
    },

    getPermissions() {
      return this.$store.state.access.permissions;
    },
  },
  methods: {
    async updateToggle(value) {
      this.cardData.forEach((card) => {
        card.permit = value;
        this.isSwitched = value;
        this.nextBtn = value;
        if (value) {
          this.$store.commit("access/SET_PERMISSIONS", this.getPermissions);
        } else {
          this.$store.commit("access/SET_PERMISSIONS", []);
        }
      });
    },

    reset() {
      this.isSwitched = false;
      this.cardData.forEach((card) => {
        card.permit = false;
      });
    },

    async updateValue(value, index) {
      if (index === undefined || index === null) {
        return;
      }

      const card = this.cardData[index];
      if (card.permit === value) {
        return;
      }

      card.permit = value;
      this.nextBtn = value ?? false;

      const switchedOnIndexes = this.cardData.reduce(
        (acc, el, i) => (el.permit ? [...acc, i] : acc),
        []
      );

      const allSwitchesToggled =
        switchedOnIndexes.length === this.cardData.length;
      this.isSwitched = allSwitchesToggled;

      const managerPerm = this.calculateManagerPermissions(
        switchedOnIndexes,
      );
      await this.$store.commit("access/SET_PERMISSIONS", managerPerm);
    },

    calculateManagerPermissions(switchedOnIndexes) {
      if (switchedOnIndexes.length === 0) {
        return [];
      } else if (switchedOnIndexes.length === 1) {
        return [this.cardData[switchedOnIndexes[0]].permission];
      } else {
        return switchedOnIndexes.map((i) => this.cardData[i].permission);
      }
    }
    ,

    async updateModel(value) {
      this.nextBtn = value ?? false;
      this.cardData.forEach((card) => {
        card.permit = value;
        this.nextBtn = value;
      });
    },
  },
};
</script>
