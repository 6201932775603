<template>
  <div class="input_box" :class="className">
    <div class="mb-2">
      <label class="relative inline-flex items-center cursor-pointer">
        <input
          type="checkbox"
          id="default-input"
          :required="requireTag"
          :value="modelValue"
          v-bind="$attrs"
          v-model="isChecked"
          :checked="isChecked"
          :disabled="disabledComponentState"
        />
        <div
          data-cy="allowCompetency"
          class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-0.5 after:left-[2px] peer-checked:after:bg-[#dd5928] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-white-600"
        ></div>
        <span class="ml-3 font-medium text-gray-900 dark:text-gray-300">{{
          label
        }}</span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: Number,
      default: 500,
    },
    label: {
      type: String,
      default: "",
    },
    requireTag: {
      type: Boolean,
      default: false,
    },
    disabledComponentState: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    isChecked: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("updateModel", value);
      }
    }
  },
};
</script>

<style scoped>
.input_box {
  width: 100%;
}
</style>
